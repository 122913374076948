import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyCfbnnFsYb9tbKeQAahDL5tXwmudrAIe-w',
  authDomain: 'smartcar-fuel-os.firebaseapp.com',
  databaseURL: 'https://smartcar-fuel-os.firebaseio.com',
  projectId: 'smartcar-fuel-os',
  storageBucket: 'smartcar-fuel-os.appspot.com',
  messagingSenderId: '253814135138',
  appId: '1:253814135138:web:3765e6ad7e35662333b43b',
  measurementId: 'G-27FJMEYGEN',
};
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const functions = firebase.functions();

if (process.env.NODE_ENV === 'development') {
  functions.useFunctionsEmulator('http://localhost:5000');
}

export { auth, firebase, firestore, functions };
