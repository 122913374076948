import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${require('images/homepage.png')});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 95vh;
`;

const Overlay = styled.img`
  width: 100%;
`;

const PoweredBy = styled.p`
  font-size: 13px;
  color: #000;
`;

const LogoContainer = styled.div`
  margin-top: -150px;
  text-align: center;
`;

const HomeContainer = () => (
  <Link to="/welcome">
    <Container>
      <Overlay alt="overlay" src={require('images/homepage-overlay.svg')} />
      <LogoContainer>
        <img alt="Shockz" src={require('images/shockz.svg')} />
        <PoweredBy>
          <strong>Supercharged EV Rewards</strong>
        </PoweredBy>
      </LogoContainer>
    </Container>
  </Link>
);

export default HomeContainer;
