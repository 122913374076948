import React from 'react';
import styled from 'styled-components';
import { H3 } from 'components/Headings';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
`;

const Block = styled.div`
  h3 {
    margin: 1rem;
    img {
      margin-right: 10px;
    }
  }
  ul {
    list-style: none;
    text-align: left;
    margin: 0;
    padding: 0;
  }

  li:nth-child(odd) {
    background: #312748;
  }

  li {
    display: flex;
    font-size: 18px;
    padding: 1rem;
  }

  span {
    width: 50%;
  }
`;

const VehicleDetails = ({ odometer, vin, info, location, battery, charge }) => (
  <Container>
    <Block>
      <H3>Vehicle Info</H3>
      <ul>
        <li>
          <span>Make</span>
          {info.make}
        </li>
        <li>
          <span>Model</span>
          {info.model}
        </li>
        <li>
          <span>Year</span> {info.year}
        </li>
        <li>
          <span>VIN</span> {vin}
        </li>
      </ul>
    </Block>
    <Block>
      <H3>
        <img alt="Battery" src={require('images/battery.svg')} /> Battery
      </H3>
      <ul>
        <li>
          <span>Range</span>
          {battery.data.range} miles
        </li>
        <li>
          <span>Charge</span>
          {battery.data.percentRemaining * 100}%
        </li>
      </ul>
    </Block>
    <Block>
      <H3>
        <img alt="Odometer" src={require('images/odometer.svg')} /> Odometer
      </H3>
      <ul>
        <li>
          <span>Mileage</span> {odometer.data.distance.toFixed(2)} miles
        </li>
      </ul>
    </Block>
  </Container>
);

export default VehicleDetails;
