import React from 'react';
import styled from 'styled-components';
import { firebase } from 'firebase.config';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url(${require('images/onboarding-top.png')});
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  height: 100vh;
`;

const Container = styled.div`
  background-image: url(${require('images/onboarding-bottom.png')});
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  p {
    color: #443f3f;
    font-size: 18px;
  }

  button {
    margin: 0 auto;
  }
`;

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'redirect',
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      const isNewUser = authResult.additionalUserInfo.isNewUser;
      // If new user redirect them into the onboarding flow otherwise straight to their profile.
      window.location.assign('/onboarding');
      return false;
      // if (isNewUser) {
      //   window.location.assign('/onboarding');
      // } else {
      //   window.location.assign('/profile');
      // }
      // return false;
    },
  },
};

const LoginContainer = () => (
  <Page>
    <Container>
      <img alt="Shockz" src={require('images/shockz.svg')} />
      <p>Let's get started</p>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </Container>
  </Page>
);

export default LoginContainer;
