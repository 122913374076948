import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background-image: url(${require('images/onboarding.png')});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
`;

const Text = styled.p`
  font-size: 20px;
  color: #000;
`;
const WelcomeMessage = styled.div`
  width: 50%;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 50px;
`;

const WelcomeContainer = () => (
  <Link to="/welcome/rewards">
    <Container>
      <WelcomeMessage>
        <Text>
          <strong>Drive smart</strong> by connecting your electric vehicles to
        </Text>
        <img alt="Shockz" src={require('images/shockz.svg')} />
      </WelcomeMessage>
    </Container>
  </Link>
);

export default WelcomeContainer;
