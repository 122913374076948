import React, { useContext } from 'react';
import styled from 'styled-components';
import { useDocument } from 'react-firebase-hooks/firestore';
import { firebase } from 'firebase.config';
import { H1 } from 'components/Headings';
import { AuthContext } from 'components/Auth';
import VehicleDetails from 'components/VehicleDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #211933;
  color: #fff;
  font-size: 22px;
`;

const Picture = styled.div`
  display: flex;
  padding: 10px 40px;
  background: #fff;
  h1 {
    color: #000;
    font-size: 18px;
  }
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 20px;
`;

const Bottom = styled.div`
  img {
    width: 400px;
    margin: 0 auto;
  }
  background: #fff;
  display: flex;
  align-items: center;
`;

const Profile = () => {
  const { currentUser } = useContext(AuthContext);
  const [value, loading, error] = useDocument(firebase.firestore().doc(`cars/${currentUser.uid}`));

  if (loading) return null;
  const document = value.data();

  return (
    <Container>
      <img alt="Tesla" src={require('images/tesla.png')} />
      <Picture>
        <Avatar alt="avatar" src={currentUser.photoURL} />
        <H1>{currentUser.displayName}</H1>
      </Picture>
      <VehicleDetails {...document} />
      <Bottom>
        <img alt="Profile" src={require('images/profile.png')} />
      </Bottom>
    </Container>
  );
};

export default Profile;
