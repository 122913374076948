import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100vh;
  img {
    width: 100%;
  }
`;

const Text = styled.p`
  font-size: 22px;
  color: #000;
`;
const WelcomeMessage = styled.div`
  width: 60%;
  margin: 50px auto 0;
  text-align: center;
`;

const WelcomeRewards = () => (
  <Link to="/login">
    <Container>
      <WelcomeMessage>
        <Text>
          Be rewarded with personalised insights for{' '}
          <strong>environmentally friendly behaviour</strong>
        </Text>
      </WelcomeMessage>
      <img alt="Mountains" src={require('images/onboarding2.png')} />
    </Container>
  </Link>
);

export default WelcomeRewards;
