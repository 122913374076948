import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Smartcar from '@smartcar/auth';
import styled from 'styled-components';
import { H1 } from 'components/Headings';
import Button from 'components/Button';
import { AuthContext } from 'components/Auth';
import { firebase } from 'firebase.config';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url(${require('images/onboarding-top.png')});
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  height: 100vh;
`;

const Container = styled.div`
  background-image: url(${require('images/onboarding-bottom.png')});
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-top: 60%;
  height: 100vh;

  h1 {
    font-size: 20px;
  }

  p {
    color: #443f3f;
    font-size: 18px;
    width: 70%;
    margin: 10px auto;
  }

  button {
    margin: 0 auto;
  }
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  vertical-align: middle;
  border-radius: 50%;
`;

function Spinner() {
  return (
    <img
      className="spinner"
      alt="loading"
      width="24"
      height="24"
      src="https://i.pinimg.com/originals/3e/f0/e6/3ef0e69f3c889c1307330c36a501eb12.gif"
    />
  );
}

const Onboarding = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const smartcar = new Smartcar({
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri:
      'https://javascript-sdk.smartcar.com/v2/redirect?app_origin=https://shockz.appliedinnovationexchange.com',
    scope: [
      'required:read_vehicle_info',
      'read_battery',
      'read_charge',
      'read_location',
      'read_odometer',
      'read_vin',
    ],
    testMode: true,
    onComplete: onComplete,
  });

  function authorize() {
    smartcar.openDialog({ forcePrompt: true });
  }

  async function onComplete(err, code, status) {
    setLoading(true);
    try {
      if (code) {
        const response = await fetch(`/api/exchange?code=${code}`);
        if (response.status === 200) {
          const data = await response.json();
          console.log(data);

          const addVehicle = firebase.functions().httpsCallable('vehicle');
          const addVehicleResponse = await addVehicle({ accessToken: data.accessToken });

          if (addVehicleResponse) {
            history.push('/onboarding/confirmation');
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Page>
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <H1>{currentUser.displayName}</H1>
            <Avatar alt="avatar" src={currentUser.photoURL} />
            <p>
              Connect your electic vehicle to let Shockz locate and analyse it without your
              assistance
            </p>
            <Button onClick={authorize}>Connect</Button>
          </>
        )}
      </Container>
    </Page>
  );
};

export default withRouter(Onboarding);
