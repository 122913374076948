import React from 'react';
import styled from 'styled-components';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'components/Auth';
import GlobalStyle from 'theme/globalStyle';

import Home from 'pages/Home';
import Onboarding from 'pages/Onboarding';
import OnboardingConfirm from 'pages/OnboardingConfirm';
import Welcome from 'pages/Welcome';
import Login from 'pages/Login';
import WelcomeRewards from 'pages/WelcomeRewards';
import Profile from 'pages/Profile';

const PageContainer = styled.div`
  max-width: 440px;
  margin: auto;
`;

function App() {
  return (
    <BrowserRouter>
      <PageContainer>
        <GlobalStyle />
        <AuthProvider>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/welcome" component={Welcome} />
            <Route exact path="/welcome/rewards" component={WelcomeRewards} />
            <Route exact path="/onboarding" component={Onboarding} />
            <Route exact path="/onboarding/confirmation" component={OnboardingConfirm} />
            <Route exact path="/profile" component={Profile} />
          </Switch>
        </AuthProvider>
      </PageContainer>
    </BrowserRouter>
  );
}

export default App;
