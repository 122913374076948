import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'firebase.config';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, initialising] = useAuthState(auth);

  const signout = () => {
    return auth.signOut();
  };

  if (initialising) return null;

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        signout,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
