import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Button from 'components/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #211933;
  height: 100vh;
  color: #fff;
  font-size: 22px;
`;

const MessageContainer = styled.div`
  width: 50%;
  text-align: center;
  margin: 20px auto;
`;

const OnboardingConfirm = ({ history }) => (
  <Container>
    <img alt="Tesla" src={require('images/tesla.png')} />
    <MessageContainer>
      <p>Great news, your Tesla Model 3 has successfully connect to Shockz!</p>
      <Button onClick={() => history.push('/profile')}>Lets get started</Button>
      <img alt="Shockz" src={require('images/shockz-white.svg')} />
    </MessageContainer>
  </Container>
);

export default withRouter(OnboardingConfirm);
