import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    background-color: #fff;
  }
  html, body, #root {
    color: #000;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
  }
  p {
    font-size: 18px;
  }
  a {
    text-decoration: none;
  }
`;

export default GlobalStyle;
