import styled from 'styled-components';

const H1 = styled.h1`
  font-size: 34px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
`;

const H3 = styled.h3`
  font-size: 21px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
`;

export { H1, H3 };
