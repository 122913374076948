import React from 'react';
import styled from 'styled-components';

export const StyledButton = styled.button`
  align-items: center;
  padding: 0.5rem ${props => (props.icon ? '80px' : '2rem')};
  margin: 1rem 0;
  height: 64px;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  background: #E31937;
  opacity: 0.95;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 28.5px;
  display: flex;
  border: none;
  &:hover {
    cursor: pointer;
  }
  font-size: 18px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  position: relative;

  ::after {
    content: '';
    background-image: url("${props => props.icon}");
    background-size: contain;
    background-position: center center;
    height: 20px;
    background-repeat: no-repeat;
    width: 20px;
    position: absolute;
    right: 40px;
  }
`;

const Button = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default Button;
